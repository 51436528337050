import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"


const Header = ({ title, description }) => (
  <header className="bg-green-600 shadow" style={{ fontFamily: 'Nunito Sans' }}>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charset="utf-8" />
      <meta name="google-site-verification" content="w4jXaCZxtgznqChkEkBz_J8RHFAoFU8GRnUHJP8U4mw" />
      {/* <link href="https://fonts.googleapis.com/css?family=Nunito+Sans" rel="stylesheet" /> */}
    </Helmet>
    <div className="container mx-auto p-3 flex justify-center sm:justify-start">
      <Link to="/" className="text-xl text-white lowercase text-center">
        {title}
      </Link>
      <div className="flex-grow flex justify-end items-center text-xs text-green-900">
        {/* <Link to="/ajuda" className="ml-8">
          Links
        </Link> */}
        <Link to="/termos-de-uso/" className="ml-1">
          Termos de uso
        </Link>
        <div className="mx-1">
          &middot;
        </div>
        <Link to="/ajuda/">
          Ajuda
        </Link>

      </div>
      {/* <div className="hidden sm:block flex-grow text-center text-gray-400">
        {description}
      </div> */}
    </div>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

Header.defaultProps = {
  title: ``,
  description: ``
}

export default Header
