/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
// import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
      allPubliJson {
        edges {
          node {
            title
            img
            url
          }
        }
      }
    }
  `)

  const publi = data.allPubliJson.edges.map(item => item.node)

  publi.sort(() => Math.random() - 0.5)

  const ads = publi.slice(0, 3)

  return (
    <div className="flex">
      <div className="" style={{ flexBasis: "0px" }}></div>

      <div className="flex-1">
        <Header {...data.site.siteMetadata} />
        <main className="container mx-auto my-2 flex">
          <div className="flex-1 p-1">{children}</div>
          {/* <div className="flex-none mt-2 mt-12">
            <div className="text-sm text-gray-500 mb-4 text-center">
              Publicidade
            </div>
            <div>
              {ads.map(item => (
                <div className="flex justify-center p-2 mt-6">
                  <div className="w-24">
                    <a href={item.url} target="_blank">
                      <img src={item.img} alt={item.title} />
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <a
                className="text-sm text-center"
                target="_blank"
                href="https://www.amazon.com.br/b?_encoding=UTF8&tag=tt0551-20&linkCode=ur2&linkId=a727a069d6bbfcd91e2c02b243793ac3&camp=1789&creative=9325&node=7872854011"
              >
                Mais sugestões
              </a>
            </div>
          </div> */}
        </main>
        <footer className="bg-green-800 p-4 h-32 flex justify-center items-center text-white">
          <div className="text-center">
            Taxas do Tesouro © {new Date().getFullYear()}
          </div>
          {/* <div className="mx-2"> &middot;</div>
          <div>
            <a href="mailto:contato@taxas-tesouro.com">contato@taxas-tesouro.com</a>
          </div> */}
          <div className="mx-2"> &middot;</div>
          <div className="text-center">
            <a href="mailto:jordi@serrano.solutions">
              Desenvolvido por SERRANO SOLUTIONS
            </a>
          </div>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
